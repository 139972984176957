<template>
  <div class="container">
    <el-container>
      <el-header >
        <el-row type="flex" :gutter="10" style="width: 100vw">
          <el-col :span="3">
            <el-input
              v-model="filterOption.contractName"
              :placeholder="$t('contract.contractName')"
              size="mini"
            ></el-input>
          </el-col>
          <el-col :span="3">
            <el-input
              v-model="filterOption.contractNo"
              :placeholder="$t('contract.contractNo')"
              size="mini"
            ></el-input>
          </el-col>
          <el-col :span="3">
            <el-input
              v-model="filterOption.contractPayerCode"
              :placeholder="$t('contract.contractPayerCode')"
              size="mini"
            ></el-input>
          </el-col>
          <el-col :span="3">
            <el-input
              v-model="filterOption.contractPayeeCode"
              :placeholder="$t('contract.contractPayeeCode')"
              size="mini"
            ></el-input>
          </el-col>
          <el-col :span="3">
            <el-select
              filterable
              v-model="filterOption.contractType"
              :placeholder="$t('contract.contractType')"
              size="mini"
              clearable
            >
              <el-option
                :label="item"
                :value="index + 1"
                v-for="(item, index) in contractTypeList"
                :key="index"
              ></el-option>
            </el-select>
          </el-col>

          <el-col :span="4" :offset="5">
            <div style="float: right">
              <el-button type="primary" size="mini" @click="doSearch">{{
                $t("system.search")
              }}</el-button>
              <el-button type="warning" size="mini" @click="doReset">{{
                $t("system.reset")
              }}</el-button>
            </div>
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <div class="tableHeader">
          <el-button size="mini" @click="showCreateDialog">{{
            $t("system.create")
          }}</el-button>
        </div>
        <el-table
          :data="tableData"
          ref="contractTable"
          border
          :height="tHeight"
          v-loading="isTableLoading"
          @selection-change="onTableSelectionChange"
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column type="selection" width="40" fixed></el-table-column>
          <el-table-column
            type="index"
            :label="$t('system.index')"
            width="60"
            align="center"
            fixed
          ></el-table-column>
          <el-table-column
            prop="contractNo"
            :label="$t('contract.contractNo')"
            align="center"
            show-overflow-tooltip
            width="150"
          ></el-table-column>
          <el-table-column
            prop="contractName"
            :label="$t('contract.contractName')"
            align="center"
            width="180"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="contractPayerCode"
            :label="$t('contract.contractPayerCode')"
            align="center"
            width="120"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="contractPayerName"
            :label="$t('contract.contractPayerName')"
            align="center"
            width="180"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="contractPayeeCode"
            :label="$t('contract.contractPayeeCode')"
            align="center"
            width="120"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="contractPayeeName"
            :label="$t('contract.contractPayeeName')"
            align="center"
            width="180"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :label="$t('contract.contractType')"
            align="center"
            width="180"
            show-overflow-tooltip
          >
            <template #default="scope">
              <span>
                {{ contractTypeList[scope.row.contractType - 1] }}
              </span>
            </template></el-table-column
          >
          <el-table-column
            prop="startDate"
            :label="$t('contract.startDate')"
            align="center"
            width="150"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="endDate"
            :label="$t('contract.endDate')"
            align="center"
            width="150"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :label="$t('system.operation')"
            fixed="right"
            align="center"
            width="100"
          >
            <template #default="scope">
              <el-button
                type="text"
                size="mini"
                icon="el-icon-edit"
                @click="edit(scope.row)"
                >{{ $t("system.edit") }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div style="float: right; margin-top: 10px">
          <el-pagination
            @size-change="onTableSizeChange"
            @current-change="onTablePageChange"
            :current-page="filterOption.current"
            :page-sizes="[10, 50, 100, 200]"
            layout="total,sizes,prev, pager, next"
            :total="filterOption.total"
          ></el-pagination>
        </div>
      </el-main>
    </el-container>

    <create-contract-dialog
      v-if="isShowCreateDialog"
      :isShowDialog="isShowCreateDialog"
      @onDialogClose="closeCreateDialog"
    />
    <update-contract-dialog
      v-if="isShowUpdateDialog"
      :isShowDialog="isShowUpdateDialog"
      :rowInfo="currentRow"
      @onDialogClose="closeUpdateDialog"
    />
  </div>
</template>

<script>
import partnerServer from "./source/partner.js";
import mixin from "@/utils/mixin.js";
import CreateContractDialog from "./dialogs/createContract";
import UpdateContractDialog from "./dialogs/updateContract";
export default {
  name: "contract",
  components: {
    CreateContractDialog,
    UpdateContractDialog,
  },
  mixins: [mixin],
  data() {
    return {
      isTableLoading: false,
      isShowCreateDialog: false,
      isShowUpdateDialog: false,
      filterOption: {
        contractName: null,
        contractNo: null,
        contractPayerCode: null,
        contractPayeeCode: null,
        contractType: null,
        current: 1,
        size: 10,
        total: 0,
      },

      contractTypeList: [
        "跨境产品合同",
        "国际运力合同【内部】",
        "国际派送合同",
        "国际运力合同【船司】",
        "国际订舱合同【船代】",
        "国际清关合同",
        "国内报关合同",
        "国际卡车合同",
        "国际仓库合同",
        "国际拆箱合同",
        "国内零担合同",
        "国内仓库合同",
        "国内取件合同",
        "国内转运合同",
      ],

      tableData: [],
      tableSelection: [],
      currentRow: null,
    };
  },
  computed: {
    tHeight() {
      return this.tableHeight - 135;
    },
  },
  mounted() {},
  methods: {
    doSearch() {
      partnerServer
        .queryContract({
          ...this.filterOption,
          current: this.filterOption.current,
          size: this.filterOption.size,
        })
        .then((res) => {
          this.tableData = res.data ? res.data.records : [];
          this.filterOption.total = res.data.total;
        });
    },
    doReset() {
      this.filterOption = {
        contractName: null,
        contractNo: null,
        contractPayerCode: null,
        contractPayeeCode: null,
        contractType: null,
        current: 1,
        size: 10,
        total: 0,
      };
    },
    edit(row) {
      this.currentRow = row;
      this.isShowUpdateDialog = true;
    },

    onTableSelectionChange(val) {
      this.tableSelection = val;
    },
    onTableSizeChange(size) {
      this.filterOption = { ...this.filterOption, size: size, current: 1 };
      this.doSearch();
    },
    onTablePageChange(page) {
      this.filterOption = { ...this.filterOption, current: page };
      this.doSearch();
    },
    showCreateDialog() {
      this.isShowCreateDialog = true;
    },
    closeCreateDialog() {
      this.isShowCreateDialog = false;
    },
    closeUpdateDialog() {
      this.isShowUpdateDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: calc(100% - 20px);
  background-color: white;
  overflow-y: scroll;
  padding: 10px;

  .el-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 10px;
    :deep(el-form-item) {
      margin-right: 10px;
    }
  }

  :deep(.el-main) {
    padding: 0 20px;
    .tableHeader {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 48px;
    }
  }
}
</style>

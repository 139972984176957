<template>
  <el-dialog
    :title="$t('contract.createContract')"
    v-model="isShow"
    width="700px"
    :before-close="handleClose"
  >
    <el-form
      ref="contractForm"
      :inline="true"
      :model="contractInfo"
      size="mini"
      :rules="contractRules"
    >
      <div class="row">
        <el-form-item
          :label="$t('contract.contractNo')"
          prop="contractNo"
          label-width="90px"
        >
          <el-input
            v-model.trim="contractInfo.contractNo"
            :placeholder="$t('system.pleaseInput') + $t('contract.contractNo')"
            size="mini"
            style="width: 220px !important"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('contract.contractName')"
          prop="contractName"
          label-width="90px"
        >
          <el-input
            v-model.trim="contractInfo.contractName"
            :placeholder="
              $t('system.pleaseInput') + $t('contract.contractName')
            "
            style="width: 220px !important"
            size="mini"
          ></el-input>
        </el-form-item>
      </div>

      <div class="row">
        <el-form-item
          :label="$t('contract.partyA')"
          prop="partyA"
          label-width="90px"
        >
          <el-select
            filterable
            v-model="contractInfo.partyA"
            :placeholder="$t('system.pleaseSelect') + $t('contract.partyA')"
            @change="onSelectPartyA"
            style="width: 220px !important"
          >
            <el-option
              :label="item.companyNameCn"
              :value="index"
              v-for="(item, index) in customerList"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('contract.partyB')"
          prop="partyB"
          label-width="90px"
        >
          <el-select
            filterable
            v-model="contractInfo.partyB"
            :placeholder="$t('system.pleaseSelect') + $t('contract.partyB')"
            clearable
            @change="onSelectPartyB"
            style="width: 220px !important"
          >
            <el-option
              :label="item.companyNameCn"
              :value="index"
              v-for="(item, index) in customerList"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item
          :label="$t('contract.contractType')"
          prop="contractType"
          label-width="90px"
        >
          <el-select
            filterable
            v-model="contractInfo.contractType"
            :placeholder="
              $t('system.pleaseSelect') + $t('contract.contractType')
            "
            style="width: 220px !important"
            clearable
          >
            <el-option
              :label="item"
              :value="index + 1"
              v-for="(item, index) in contractTypeList"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('contract.effectDate')"
          prop="effectDate"
          label-width="90px"
        >
          <el-date-picker
            v-model="contractInfo.effectDate"
            type="daterange"
            range-separator="-"
            :start-placeholder="$t('contract.startDate')"
            :end-placeholder="$t('contract.endDate')"
            style="width: 220px !important"
          >
          </el-date-picker>
        </el-form-item>
      </div>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose" size="mini">{{
          $t("system.cancel")
        }}</el-button>
        <el-button type="primary" @click="doCreateContract" size="mini">{{
          $t("system.confirm")
        }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import partnerServer from "../source/partner";
export default {
  props: ["isShowDialog"],
  emits: ["onDialogClose"],
  data() {
    return {
      contractInfo: {
        contractNo: null,
        contractName: null,
        partyA: null,
        contractPayerCode: null,
        contractPayerName: null,
        partyB: null,
        contractPayeeCode: null,
        contractPayeeName: null,
        contractType: null,
        startDate: null,
        endDate: null,
        effectDate: null,
      },
      contractRules: {
        contractNo: [
          {
            required: true,
            message:
              this.$t("system.pleaseInput") + this.$t("contract.contractNo"),
            trigger: "blur",
          },
        ],
        contractName: [
          {
            required: true,
            message:
              this.$t("system.pleaseInput") + this.$t("contract.contractName"),
            trigger: "blur",
          },
        ],
        partyA: [
          {
            required: true,
            message:
              this.$t("system.pleaseSelect") + this.$t("contract.partyA"),
            trigger: "blur",
          },
        ],
        partyB: [
          {
            required: true,
            message:
              this.$t("system.pleaseSelect") + this.$t("contract.partyB"),
            trigger: "blur",
          },
        ],
        contractType: [
          {
            required: true,
            message:
              this.$t("system.pleaseSelect") + this.$t("contract.contractType"),
            trigger: "change",
          },
        ],
        effectDate: [
          {
            required: true,
            message:
              this.$t("system.pleaseSelect") + this.$t("contract.effectDate"),
            trigger: "change",
          },
        ],
      },
      // customerList: [
      //   { id: 1, name: "壹站供应链管理有限公司", companyCode: "YZ" },
      //   { id: 2, name: "上海道骊物流有限公司", companyCode: "DL" },
      // ],
      contractTypeList: [
        "跨境产品合同",
        "国际运力合同【内部】",
        "国际派送合同",
        "国际运力合同【船司】",
        "国际订舱合同【船代】",
        "国际清关合同",
        "国内报关合同",
        "国际卡车合同",
        "国际仓库合同",
        "国际拆箱合同",
        "国内零担合同",
        "国内仓库合同",
        "国内取件合同",
        "国内转运合同",
      ],
    };
  },
  computed: {
    customerList(){
      return this.$store.state.constants.saleCompanys;
    },
    isShow() {
      return this.isShowDialog;
    },
  },
  methods: {
    onSelectPartyA(index) {
      this.contractInfo = {
        ...this.contractInfo,
        contractPayerCode: this.customerList[index].companyCode,
        contractPayerName: this.customerList[index].companyNameCn,
      };
    },
    onSelectPartyB(index) {
      this.contractInfo = {
        ...this.contractInfo,
        contractPayeeCode: this.customerList[index].companyCode,
        contractPayeeName: this.customerList[index].companyNameCn,
      };
    },

    getSubmitParam() {
      this.contractInfo = {
        ...this.contractInfo,
        startDate: this.contractInfo.effectDate[0],
        endDate: this.contractInfo.effectDate[1],
      };
      return this.contractInfo;
    },

    doCreateContract() {
      this.$refs["contractForm"].validate((valid) => {
        if (valid) {
          partnerServer.addContract(this.getSubmitParam()).then(() => {
            this.$confirm(
              this.$t("contract.createSuccess"),
              this.$t("system.continue") + "?",
              {
                confirmButtonText: this.$t("system.confirm"),
                cancelButtonText: this.$t("system.cancel"),
                type: "success",
              }
            )
              .then(() => {
                this.$refs["contractForm"].resetFields();
              })
              .catch(() => {
                this.handleClose();
              });
          });
        }
      });
    },
    handleClose() {
      this.$emit("onDialogClose");
    },
  },
};
</script>

<style lang="less" scoped>
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  :deep(.el-form-item__label) {
    font-size: 12px;
  }
}
</style>
